html, body, #root {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #000;
  color: #ffffff;
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

div, h1, h2, h3, h4, h5, h6, p, span, input, button, li {
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  color: #ffffff;
}

/**
 * Links
 */

a, .link {
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: rgba(255,255,255,0.6);
  text-decoration: none;
  transition: all 0.14s ease;
}

a:hover, .link:hover {
  cursor: pointer;
  color: rgba(255,255,255,1);
}

a:active, .link:active {
  color: rgba(255,255,255,0.6);
}

/**
 * Buttons
 */

.buttonPrimaryLarge {
  text-align: center;
  font-size: 18px;
  width: 100%;
  max-width: 360px;
  height: auto;
  margin: 20px 0 0 0;
  padding: 24px 24px 28px 24px;
  background: #fd5750;
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-weight: 600;
  border-radius: 4px;
  border: 0px;
  transition: all 0.14s ease;
  user-select: none;
  outline: none;
  text-transform: lowercase;
}

.buttonPrimaryLarge:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.buttonPrimaryLarge:active {
  cursor: pointer;
  transform: scale(1);
  outline: none;
}

/**
 * Animations
 */

.animateFadeIn {
  animation: fadeIn 0.45s;
}

.animateScaleIn {
  animation: scaleIn 0.35s;
}

.animateFlicker {
  animation: flicker 2.5s infinite;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.8); 
  }
  to   { 
    opacity: 1;
    transform: scale(1); 
  }
}

@keyframes flicker {
	0% {
		opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
	100% {
		opacity: 0.3;
	}
}