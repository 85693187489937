.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.containerInner {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #000;
  width: 100%;
  height: 100%;
  padding: 0 0 30px 0;
}

.navigationContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  box-sizing: border-box;
  background: #000;
  width: 100%;
  height: 80px;
  padding: 25px 45px 0 45px;
}

.navigationContainer div {
  margin-left: 40px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  box-sizing: border-box;
  background: #000;
  width: 100%;
  height: 100%;
  padding: 7% 15px 45px 15px;
}

.welcomeMessage {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  width: 100%;
  height: auto;
  font-size: 28px;
}

.artwork {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 250px;
}

.artwork img {
  width: auto;
  height: auto;
  align-self: center;
  max-height: 100%;
  max-width: 500px;
  user-select: none;
}

/**
 * Special
 */

::-moz-selection { 
  background: #fd5750; 
  color: #ffffff;
  opacity: 1;
}

::selection { 
  background: #fd5750; 
  color: #ffffff;
  opacity: 1;
}